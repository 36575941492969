<template>
  <div class="flex flex-row items-center flex-grow w-auto h-full background-login lg:bg-none min-h-screen-action-bar">
      <div class="justify-center hidden w-1/2 lg:flex">
        <div class="overflow-auto text-4xl font-light text-gray-800 truncate xl:text-5xl label-postion" >
            iBS-Apps
        </div>
        <div class="w-2/3 max-w-lg ">
          <LoginPicture/>
        </div>
      </div>
      <div class="flex flex-row justify-center w-full lg:w-1/2">
        <div class="sm:w-3/5">
          <Card class="flex flex-col justify-center px-2 pt-8 pb-16 sm:px-8 xl:pt-0 xl:pb-0 xl:px-14 height-desktop" content>
            <div class="">
              <h2 class="mb-6 text-5xl font-light text-center text-gray-800 ">
                {{ $t('login' ) }}
              </h2>
              <form @submit.prevent="login">
                <div class="">
                  <TextField
                    v-model="user"
                    :label="$t('username')"
                    :custom-error="authError"
                    :custom-error-msg="$t('loginError')"
                    :floating="hasFloatingWidth"
                  />
                  <TextField
                    v-model="password"
                    type="password"
                    :label="$t('password')"
                    password-reveal
                    :floating="hasFloatingWidth"
                  />
                  <Button
                    type="submit"
                    class="w-full mt-12"
                    primary
                  >
                    {{ $t('signin') }}
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </div>
      </div>
    </div>
</template>

<script>
import LoginPicture from '@/ui/components/LoginPicture.vue';

export default {
  data() {
    return {
      user: null,
      password: null,
    };
  },
  components: {
    LoginPicture,
  },
  created() {
    this.$store.dispatch('initAuth');
  },
  computed: {
    hasFloatingWidth() {
      return window.innerWidth < 1024;
    },
    authToken() {
      return this.$store.state.authentication.authToken;
    },
    authError() {
      return !!this.$store.state.authentication.authError;
    },
    redirect() {
      const { query } = this.$route;
      if (query && query.redirect) {
        return query.redirect;
      }
      return null;
    },
  },
  watch: {
    authToken: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (typeof this.redirect === 'string') {
            this.$router.push({ name: this.redirect })
              // Suppress error of redirecting more than once.
              // TODO: Build routes in a way to only redirect max. once.
              .catch(() => {});
          } else {
            this.$router.push({ name: 'apps' });
          }
        }
      },
    },
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        username: this.user,
        password: this.password,
      });
    },
  },
};
</script>

<style scoped>

.label-postion {
  position: relative;
  top: 25px;
  left: 12%;
}

@media (min-width: 1024px) {
  .height-desktop {
    min-height: 60vh;
  }
}

</style>
